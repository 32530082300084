<template>
  <div v-show="showPage">
    <div style="height: 0; opacity: 0; overflow: hidden; min-width:790px; width:790px; max-width:unset;">
      <b-card
        id="pdfGenerateDiv"
        ref="pdfGenerateDivRef"
        no-body
        class="invoice-preview-card"
      >
        <div
          v-for="pg in pdfTotalPage"
          :key="pg"
        >
          <div
            class="card__inner"
            style="height: 1080px;"
          >
            <b-card-body class="invoice-padding pb-0">
              <div
                class="
                  d-flex
                  justify-content-between
                  flex-md-row flex-column
                  invoice-spacing
                  mt-0
                "
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <img
                      style="width:60px; height:60px;"
                      :src="purchaseRequest.company ? (purchaseRequest.company.logo ? purchaseRequest.company.logo : '/nirvana-memorial-garden-logo.png') : '/nirvana-memorial-garden-logo.png'"
                      alt="Logo"
                      crossorigin="anonymous"
                    >
                    <!-- <img
                      style="width:60px; height:60px;"
                      :src="'/nirvana-memorial-garden-logo.png'"
                      alt="Logo"
                    > -->
                  </div>
                </div>

                <div
                  class="px-2"
                  style="min-width: fit-content; width: fit-content;"
                >
                  <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: 900; font-size: 12px; line-height: 18px;">
                    Purchase Requisition form (PRF)
                  </h4>
                </div>
                <div class="mt-md-0 mt-2 text-right">
                  <h4
                    class="invoice-title"
                    style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                  >
                    {{ purchaseRequest.caseID }}
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">Requested By:</span>
                      {{ purchaseRequest.createdBy ? purchaseRequest.createdBy.name : '' }}, {{ purchaseRequest.department ? purchaseRequest.department.name : '' }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(purchaseRequest.createdAt) }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0 main__body">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col
                  v-if="pg == 1"
                  id="requester-header"
                  cols="12"
                  xl="12"
                  class="p-0"
                >
                  <h6 class="mb-2">
                    Requester Details
                  </h6>
                  <b-table-simple
                    borderless
                    responsive
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Entity / Company
                        </b-th>
                        <b-td>{{ purchaseRequest.company ? purchaseRequest.company.name : '' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Justification / Purpose of Purchase
                        </b-th>
                        <b-td>{{ purchaseRequest.purpose }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Required Delivery Date
                        </b-th>
                        <b-td>{{ dateFormat(purchaseRequest.deliveryDate) }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Deliver To
                        </b-th>
                        <b-td>{{ purchaseRequest.deliverTo ? purchaseRequest.deliverTo.name : '' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Address
                        </b-th>
                        <b-td>{{ purchaseRequest.address }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Additional Remarks
                        </b-th>
                        <b-td
                          id="remarksFieldMain"
                          class="text-pre"
                          :class="{ 'fs-7': lowFont }"
                        >{{ purchaseRequest.remarks }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
                <b-col
                  cols="12"
                  class="p-0 item__description_table"
                >
                  <!-- Invoice Description: Table -->
                  <table
                    v-if="chunksOfItemRequests[pg - 1] && chunksOfItemRequests[pg - 1].length"
                    style="page-break-after: auto;"
                    class="pdf__table"
                  >
                    <thead style="display: table-header-group;">
                      <tr>
                        <th>No</th>
                        <th>item description</th>
                        <th>QTY</th>
                        <th>Unit</th>
                        <th>Est. total cost (S$)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in chunksOfItemRequests[pg - 1]"
                        :key="index"
                        style="page-break-inside: avoid; page-break-after: auto;"
                      >
                        <td style="page-break-inside: avoid; page-break-after: auto;">
                          {{ resolveItemCounter(pg, index) }}
                        </td>
                        <td style="page-break-inside: avoid; page-break-after: auto; width: 500px;">
                          <div style="page-break-inside: avoid; page-break-after: auto;">
                            <strong>{{ item.itemsMaster }}</strong>
                            <br>
                            <span class="text-pre">{{ item.description }}</span>
                          </div>
                        </td>
                        <td style="page-break-inside: avoid; page-break-after: auto;">
                          {{ item.quantity }}
                        </td>
                        <td style="page-break-inside: avoid; page-break-after: auto;">
                          {{ item.unit }}
                        </td>
                        <td style="page-break-inside: avoid; page-break-after: auto;">
                          {{ numberFormat(item.totalCost) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-card-body>

            <div
              v-if="pg == pdfTotalPage"
              style="position: relative; bottom: 0; width: 100%;"
            >
              <div :style="{ height: (gapHeight > 50 ? (gapHeight - 50) : gapHeight)+'px' }" />
              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0 footer__area">
                <div class="signatories_block">
                  <b-row>
                    <!-- Col: Sales Persion -->
                    <b-col
                      cols="3"
                      md="3"
                      class="mt-md-0 mt-3"
                      order="2"
                      order-md="1"
                    >
                      <b-card-text class="mb-0">
                        <span class="font-weight-bold">1. Requested By</span>
                        <div class="sign-box">
                          <div v-if="purchaseRequestForm && purchaseRequestForm.createdBySign == true">
                            <!-- <div v-if="purchaseRequest.createdBy && purchaseRequest.createdBy.signature">
                              <b-img
                                class="mb-1 mb-sm-0 user__avatar"
                                fluid
                                height="50"
                                :src="purchaseRequest.createdBy.signature"
                              />
                            </div> -->
                            <div v-if="purchaseRequest.createdBy && purchaseRequest.createdBy.name">
                              <span>{{ purchaseRequest.createdBy.name }}</span>
                            </div>
                          </div>
                          <div v-else>
                            <p>Pending Signature</p>
                          </div>
                        </div>
                        <p class="ml-75 semi-bold">
                          {{ purchaseRequest.createdBy ? purchaseRequest.createdBy.name : '' }}
                        </p>
                        <p class="ml-75">
                          {{ purchaseRequest.department ? purchaseRequest.department.name : '' }}
                        </p>
                        <p class="ml-75">
                          {{ purchaseRequestForm ? (purchaseRequestForm.createdBySign == true ? dateFormatWithTime(purchaseRequestForm.createdBySignDate) : '-') : '-' }}
                        </p>
                      </b-card-text>
                    </b-col>
                    <b-col
                      cols="3"
                      md="3"
                      class="mt-md-0 mt-3"
                      order="2"
                      order-md="1"
                    >
                      <b-card-text class="mb-0">
                        <span class="font-weight-bold">2. Confirmed By</span>
                        <div class="sign-box">
                          <div v-if="purchaseRequestForm && purchaseRequestForm.confirmedBySign == true">
                            <!-- <div v-if="purchaseRequestForm.confirmedBy && purchaseRequestForm.confirmedBy.signature">
                              <b-img
                                class="mb-1 mb-sm-0 user__avatar"
                                fluid
                                height="50"
                                :src="purchaseRequestForm.confirmedBy.signature"
                              />
                            </div> -->
                            <div v-if="purchaseRequestForm.confirmedBy && purchaseRequestForm.confirmedBy.name">
                              <span>{{ purchaseRequestForm.confirmedBy.name }}</span>
                            </div>
                          </div>
                          <div v-else>
                            <p>Pending Signature</p>
                          </div>
                        </div>
                        <p class="ml-75 semi-bold">
                          {{ purchaseRequestForm ? (purchaseRequestForm.confirmedBy ? purchaseRequestForm.confirmedBy.name : '') : '' }}
                        </p>
                        <p class="ml-75">
                          {{ purchaseRequestForm ? (purchaseRequestForm.confirmedBy ? (purchaseRequestForm.confirmedBy.department.length ? purchaseRequestForm.confirmedBy.department[0].name : '') : '') : '' }}
                        </p>
                        <p class="ml-75">
                          {{ purchaseRequestForm ? (purchaseRequestForm.confirmedBySign == true ? dateFormatWithTime(purchaseRequestForm.confirmedBySignDate) : '-') : '-' }}
                        </p>
                      </b-card-text>
                    </b-col>
                    <b-col
                      cols="3"
                      md="3"
                      class="mt-md-0 mt-3"
                      order="2"
                      order-md="1"
                    >
                      <b-card-text class="mb-0">
                        <span class="font-weight-bold">3. Approved By</span>
                        <div class="sign-box">
                          <div v-if="purchaseRequestForm && purchaseRequestForm.approvedBySign == true">
                            <!-- <div v-if="purchaseRequestForm.approvedBy && purchaseRequestForm.approvedBy.signature">
                              <b-img
                                class="mb-1 mb-sm-0 user__avatar"
                                fluid
                                height="50"
                                :src="purchaseRequestForm.approvedBy.signature"
                              />
                            </div> -->
                            <div v-if="purchaseRequestForm.approvedBy && purchaseRequestForm.approvedBy.name">
                              <span>{{ purchaseRequestForm.approvedBy.name }}</span>
                            </div>
                          </div>
                          <div v-else>
                            <p>Pending Signature</p>
                          </div>
                        </div>
                        <p class="ml-75 semi-bold">
                          {{ purchaseRequestForm ? (purchaseRequestForm.approvedBy ? purchaseRequestForm.approvedBy.name : '') : '' }}
                        </p>
                        <p class="ml-75">
                          {{ purchaseRequestForm ? (purchaseRequestForm.approvedBy ? (purchaseRequestForm.approvedBy.department.length ? purchaseRequestForm.approvedBy.department[0].name : '') : '') : '' }}
                        </p>
                        <p class="ml-75">
                          {{ purchaseRequestForm ? (purchaseRequestForm.approvedBySign == true ? dateFormatWithTime(purchaseRequestForm.approvedBySignDate) : '-') : '-' }}
                        </p>
                      </b-card-text>
                    </b-col>
                    <b-col
                      cols="3"
                      md="3"
                      class="mt-md-0 mt-3"
                      order="2"
                      order-md="1"
                    >
                      <b-card-text class="mb-0">
                        <span class="font-weight-bold">4. Verified By</span>
                        <div class="sign-box">
                          <div v-if="purchaseRequestForm && purchaseRequestForm.assignedToSign == true">
                            <!-- <div v-if="purchaseRequestForm.assignedTo && purchaseRequestForm.assignedTo.signature">
                              <b-img
                                class="mb-1 mb-sm-0 user__avatar"
                                fluid
                                height="50"
                                :src="purchaseRequestForm.assignedTo.signature"
                              />
                            </div> -->
                            <div v-if="purchaseRequestForm.assignedTo && purchaseRequestForm.assignedTo.name">
                              <span>{{ purchaseRequestForm.assignedTo.name }}</span>
                            </div>
                          </div>
                          <div v-else>
                            <p>Pending Signature</p>
                          </div>
                        </div>
                        <p class="ml-75 semi-bold">
                          {{ purchaseRequestForm ? (purchaseRequestForm.assignedTo ? purchaseRequestForm.assignedTo.name : '') : '' }}
                        </p>
                        <p class="ml-75">
                          {{ purchaseRequestForm ? (purchaseRequestForm.assignedTo ? (purchaseRequestForm.assignedTo.department.length ? purchaseRequestForm.assignedTo.department[0].name : '') : '') : '' }}
                        </p>
                        <p class="ml-75">
                          {{ purchaseRequestForm ? (purchaseRequestForm.assignedToSign == true ? dateFormatWithTime(purchaseRequestForm.assignedToSignDate) : '-') : '-' }}
                        </p>
                      </b-card-text>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
            </div>
          </div>
        </div>
      </b-card>
      <div>
        <b-row>
          <b-col
            id="content-div"
            cols="12"
            class="p-0 item__description_table"
          >
            <table-content
              :el-height.sync="elHeight"
              :chunk-item-requests="chunkItemRequests"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="create-edit-form">
          <b-col cols="12">
            <h1
              v-if="user._id == (purchaseRequest.createdBy ? purchaseRequest.createdBy._id : '')"
              class="custom-header-title"
            >
              Preview Purchase Request
            </h1>
            <h1
              v-else-if="user._id == (purchaseRequest.assignedTo ? purchaseRequest.assignedTo._id : '')"
              class="custom-header-title"
            >
              Verify Purchase Request
            </h1>
            <h1
              v-else
              class="custom-header-title"
            >
              Review Purchase Request
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <section class="invoice-preview-wrapper">
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <div>
            <b-card
              class="mb-0"
            >
              <b-row class="invoice-preview">
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-left"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == 1"
                    @click="changeFileIndex(currentFileIndex - 1)"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      class="pt-25"
                      size="18"
                    />
                    <span class="align-middle">Prev</span>
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-center"
                >
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    split
                  >
                    <template #button-content>
                      <feather-icon
                        icon="FileIcon"
                        size="18"
                        class="align-middle mr-1"
                      />
                      <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ purchaseRequest.attachments ? ((purchaseRequest.attachments.length + 1).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                    </template>
                    <b-dropdown-item @click="changeFileIndex(1)">
                      {{ purchaseRequest.caseID }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-for="(file, key) in purchaseRequest.attachments"
                      :key="key"
                      @click="changeFileIndex(key + 2)"
                    >
                      {{ file ? file.name : '' }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <p
                    v-show="currentFileIndex == 1"
                    class="mt-1"
                  >
                    Purchase Requisition Form (PRF)
                  </p>
                  <p
                    v-show="currentFileIndex != 1"
                    class="mt-1"
                  >
                    {{ purchaseRequest.attachments ? (purchaseRequest.attachments[currentFileIndex - 2] ? purchaseRequest.attachments[currentFileIndex - 2].description : '') : '' }}
                  </p>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-right"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == (purchaseRequest.attachments ? (purchaseRequest.attachments.length + 1) : 1)"
                    @click="changeFileIndex(currentFileIndex + 1)"
                  >
                    <span class="align-middle">Next</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="pt-25"
                      size="18"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </div>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <div v-show="showAttachment">
              <iframe
                v-show="showPDF"
                id="attachmentpdf"
                width="100%"
                height="1145"
              />
              <b-img
                v-show="!showPDF"
                :src="imageSrc"
                fluid-grow
                alt="Fluid-grow image"
              />
            </div>
            <div v-show="!showAttachment">
              <iframe
                id="docpdf"
                width="100%"
                height="1145"
              />
            </div>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <b-card v-if="user._id == (purchaseRequest.createdBy ? purchaseRequest.createdBy._id : '') && !$route.query.type">
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">Approval Required</span>
                Ready to Submit?
              </h3>
            </template>

            <b-card-text class="mb-0">
              <span>
                If the information on this Purchase Request Form is accurate,
                e-sign this form and it will be sent to the next assigned
                signatory ({{ purchaseRequestForm.confirmedBy ? purchaseRequestForm.confirmedBy.name : '' }}) for signing.
              </span>
            </b-card-text>

            <b-form-checkbox
              v-model="secondAgree"
              value="accepted"
              unchecked-value=""
              class="custom-control-primary"
            >
              I confirm that all information is accurate on this PRF.
            </b-form-checkbox>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="purchaseRequest.status == 'cancelled' || secondAgree == '' || ((purchaseRequestForm.createdBySign ? purchaseRequestForm.createdBySign : '') == true)"
              @click="submitForApproval()"
            >
              <feather-icon
                icon="FeatherIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Sign and Submit PRF</span>
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-primary"
              class="mb-75"
              block
              :to="{ name: 'purchasing-purchase-request-show', params: { id: $route.params.id } }"
            >
              Back to Purchase Request
            </b-button>
          </b-card>
          <b-card v-else>
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">{{ approverHeader }}</span>
                {{ approverTitle }}
              </h3>
            </template>
            <b-card-text
              v-if="$route.query.type && $route.query.type == 'verify'"
              class="mb-0"
            >
              <span>
                If the information on this Purchase Request Form is ready, e-sign this request to verify it and start the quotation process.
              </span>
            </b-card-text>
            <b-card-text
              v-else
              class="mb-0"
            >
              <span class="text-bold">You are now reviewing this PRF as the signatory for "{{ approverType }}".</span>
              <br>
              <span>If the information on this PRF is accurate, e-sign this form and it will be sent to the next assigned signatory ({{ nextApproverName }}) for signing.</span>
            </b-card-text>

            <b-form-checkbox
              v-model="secondAgree"
              value="accepted"
              unchecked-value=""
              class="custom-control-primary"
            >
              I confirm that all information is accurate on this PRF.
            </b-form-checkbox>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="purchaseRequest.status == 'cancelled' || secondAgree == '' || !canSubmit"
              @click="approvePRF()"
            >
              <feather-icon
                icon="FeatherIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Sign and Approve PRF</span>
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-primary"
              class="mb-75"
              block
              :to="{ name: 'purchasing-purchase-request-show', params: { id: $route.params.id } }"
            >
              Back to Purchase Request
            </b-button>
          </b-card>

          <b-card
            v-if="$route.query.type"
            no-body
          >
            <b-card-header class="primary-card-header">
              <div class="d-flex align-items-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3 header-icon"
                >
                  <feather-icon
                    icon="DollarSignIcon"
                    class=""
                    size="20"
                  />
                </b-button>
                <h3 class="align-middle mr-2">
                  Budget
                </h3>
              </div>

              <div
                v-if="expenseItems.length"
                class="d-flex align-items-center"
              >
                <div
                  class="button-prev"
                >
                  <b-button
                    :disabled="currentIndex == 0"
                    variant="none"
                    @click="currentIndex -= 1"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="20"
                    />
                  </b-button>
                </div>
                <div
                  slot="pagination"
                  class="swiper-pagination"
                >
                  <span>{{ (currentIndex + 1).toString().padStart(2, 0) }} / {{ expenseItems.length.toString().padStart(2, 0) }}</span>
                </div>
                <div
                  class="button-next"
                >
                  <b-button
                    :disabled="(currentIndex + 1) == expenseItems.length"
                    variant="none"
                    @click="currentIndex += 1"
                  >
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="20"
                    />
                  </b-button>
                </div>
              </div>
            </b-card-header>
            <b-card-body>
              <b-badge
                v-if="purchaseRequest.budgetStatus"
                :variant="`light-${purchaseRequest.budgetStatus == 'allocated' ? 'success' : (purchaseRequest.budgetStatus == 'not-allocated' ? 'warning' : 'danger')}`"
                class="text-capitalize-first mb-2"
              >
                {{ purchaseRequest.budgetStatus }}
              </b-badge>
              <div
                v-if="expenseItems.length"
              >
                <div class="mb-1">
                  <p class="no-margin text-bold-black">
                    Budgetary Period
                  </p>
                  <span>{{ expenseItems[currentIndex].budgetPeriod.name || '-' }} ({{ dateFormat(expenseItems[currentIndex].budgetPeriod.start) + ' - ' + dateFormat(expenseItems[currentIndex].budgetPeriod.end) }})</span>
                </div>
                <div class="mb-1">
                  <p class="no-margin text-bold-black">
                    Expense Type
                  </p>
                  <span>{{ expenseItems[currentIndex].expenseCategoryType || '-' }}</span>
                </div>
                <div class="mb-1">
                  <p class="no-margin text-bold-black">
                    Cost Centre
                  </p>
                  <span>{{ expenseItems[currentIndex].costCenter.name || '-' }}</span>
                </div>
                <div class="mb-1">
                  <p class="no-margin text-bold-black">
                    Sub-budget (Expense Category)
                  </p>
                  <span>{{ expenseItems[currentIndex].expenseCategory.code || '-' }} - {{ expenseItems[currentIndex].expenseCategory.name }}</span>
                </div>
                <div class="mb-1">
                  <p class="no-margin text-bold-black">
                    Balance
                  </p>
                  <span v-if="canViewThisAction('see-balance', 'BudgetOverview')">{{ expenseItems[currentIndex].subBudget ? (`S$ ${numberFormat(expenseItems[currentIndex].subBudget.remaining)}`) : '-' }}</span>
                  <span v-else>-</span>
                </div>
                <div class="mb-1">
                  <p class="no-margin text-bold-black">
                    Allocated Amount
                  </p>
                  <span>S$ {{ numberFormat(expenseItems[currentIndex].allocated) }}</span>
                </div>
              </div>
              <div
                v-else
                class="row"
              >
                <b-col>
                  <div class="empty_block">
                    <h4>Budget not allocated yet.</h4>
                    <b-button
                      v-if="(purchaseRequest.stage == 'in-request' && purchaseRequest.status != 'cancelled' && canViewThisAction('create', 'BudgetAllocation')) || (purchaseRequest.status != 'cancelled' && canViewThisAction('allocate-budget-all-stages', 'BudgetAllocation'))"
                      type="button"
                      variant="flat-primary"
                      :disabled="!purchaseRequest.requestType"
                      :to="{ name: 'purchasing-purchase-request-allocate-budget', params: { id: $route.params.id }, query: { type: purchaseRequest.requestType, department: (purchaseRequest.createdBy ? (purchaseRequest.createdBy.department.length ? purchaseRequest.createdBy.department[0]._id : '') : '') } }"
                    >
                      <span class="align-middle">Allocate Budget</span>
                    </b-button>
                  </div>
                </b-col>
              </div>
            </b-card-body>
          </b-card>

          <b-card no-body>
            <b-card-header style="background: none !important;">
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25">
                  Comments
                </b-card-title>
              </div>
            </b-card-header>

            <!-- timeline -->
            <b-card-body>
              <app-timeline>
                <app-timeline-item
                  v-for="(opt, key) in prComments"
                  :key="key"
                  variant="success"
                >
                  <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                    <h6 style="max-width: 100%;">
                      {{ opt.createdBy.name }}
                    </h6>
                  </div>
                  <small class="timeline-item-time text-nowrap text-muted">{{ humanReadableFormat(opt.createdAt) }}</small>
                  <p>{{ opt.comment }}</p>
                </app-timeline-item>
              </app-timeline>
              <!--/ timeline -->
              <b-card-text class="mb-0">
                <b-form-textarea
                  id="h-purchaseRequest-comment"
                  v-model="extraComment"
                  trim
                  rows="3"
                  placeholder="Type to leave a comment. Comments are visible to all users who have access to this Purchase Request."
                />
              </b-card-text>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75 ml-auto"
                :disabled="extraComment == ''"
                @click="addComment()"
              >
                <span class="align-middle">Add Comment</span>
              </b-button>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BImg, BFormTextarea, BCardText, VBTooltip, BTableSimple, BTbody, BBadge,
  BTr, BTh, BTd, BFormCheckbox, BCardBody, BDropdown, BDropdownItem, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import html2pdf from 'html2pdf.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

// import PDFJSViewer from '../../components/PDFJSViewer.vue'

import TableContent from './TableContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BImg,
    BFormTextarea,
    BCardText,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BFormCheckbox,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BCardHeader,
    BCardTitle,
    BBadge,

    TableContent,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  // props: {
  //   purchaseRequest: {
  //     type: Object,
  //     required: true,
  //   },
  //   purchaseRequestForm: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      showPage: false,
      canSubmit: false,
      purchaseRequest: {},
      purchaseRequestForm: {},
      tabType: '',
      purchaseRequestSignatories: [],
      purchaseRequestItems: [],
      purchaseRequestID: '',
      stringID: '',
      comment: '',
      extraComment: '',
      approverType: '',
      approverHeader: '',
      approverTitle: '',
      nextApproverName: '',
      commentValidation: false,
      commentError: '',
      status: 'confirmed',
      statusValidation: false,
      selectedFileName: '',
      currentFileIndex: 1,
      chunkItemRequests: [],
      chunksOfItemRequests: [],
      prComments: [],
      pdfTotalPage: 1,
      elHeight: 0,
      gapHeight: 0,
      secondAgree: '',
      pdfDataJS: '',
      imageSrc: '',
      showPDF: true,
      showAttachment: false,
      showFirst: true,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Draft', code: 'draft' },
        { title: 'Pending Edit', code: 'pending edit' },
        { title: 'Pending Approval', code: 'pending approval' },
        { title: 'Verifying', code: 'verifying' },
        { title: 'Verified', code: 'verified' },
        { title: 'Cancelled', code: 'cancelled' },
      ],
      budgetExpense: {},
      expenseItems: [],
      currentIndex: 0,
      description: '',
      attachmentKey: '',
      required,
      lqas: [
        '1. Prepared by',
        '2. Requested by',
        '3. Verified by',
        '4. Recommended by',
        '5. Agreed by',
        '6. Certified by',
        '7. Confirmed by',
        '8. Supported by',
        '9. Validated by',
        '10. Approved by',
      ],
      lowFont: false,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  mounted() {
    this.$http.get(`purchase/purchase-request/${this.$route.params.id}/show`)
      .then(async response => {
        this.purchaseRequest = response.data.data || {}
        this.purchaseRequestForm = response.data.prf || {}
        this.selectedFileName = this.purchaseRequest.caseID
        this.prComments = response.data.comments
        this.budgetExpense = response.data.budgetExpense
        this.expenseItems = response.data.expenseItems
        if (this.$route.query.type) {
          const { type } = this.$route.query
          if (type === 'confirm') {
            if (this.purchaseRequestForm.confirmedBySign === true || this.purchaseRequestForm.createdBySign === false) {
              this.$router.push({ name: 'page-not-found' })
            }
            this.approverType = 'Confirmed By'
            this.approverHeader = 'Approval Required'
            this.approverTitle = 'Approve This PRF?'
            this.nextApproverName = this.purchaseRequestForm.approvedBy ? this.purchaseRequestForm.approvedBy.name : ''
          } else if (type === 'approve') {
            if (this.purchaseRequestForm.approvedBySign === true || this.purchaseRequestForm.createdBySign === false || this.purchaseRequestForm.confirmedBySign === false) {
              this.$router.push({ name: 'page-not-found' })
            }
            this.approverType = 'Approved By'
            this.approverHeader = 'Approval Required'
            this.approverTitle = 'Approve This PRF?'
            this.nextApproverName = this.purchaseRequestForm.assignedTo ? this.purchaseRequestForm.assignedTo.name : ''
          } else if (type === 'verify') {
            if (this.purchaseRequestForm.assignedToSign === true || this.purchaseRequestForm.approvedBySign === false || this.purchaseRequestForm.createdBySign === false || this.purchaseRequestForm.confirmedBySign === false) {
              this.$router.push({ name: 'page-not-found' })
            }
            this.approverType = 'Verified By'
            this.approverHeader = 'PEnding verification'
            this.approverTitle = 'Verify This PRF?'
            this.nextApproverName = ''
          }
          this.canSubmit = true
        }
        this.showPage = true
        this.enablePreview()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    changeFileIndex(key) {
      this.currentFileIndex = key
      if (key === 1) {
        this.selectedFileName = this.purchaseRequest.caseID
        // eslint-disable-next-line func-names
        // pdfViewerFrame.onload = () => {
        //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.pdfDataJS)
        // }
        // this.showFirst = false
        // const pdfViewerFrame = document.getElementById('docpdf2')
        // pdfViewerFrame.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.pdfDataJS}`)
        this.showAttachment = false
      } else {
        this.selectedFileName = this.purchaseRequest.attachments[key - 2].name
        if (this.purchaseRequest.attachments[key - 2].type.includes('pdf')) {
          // eslint-disable-next-line func-names
          // pdfViewerFrame.onload = () => {
          //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.purchaseRequest.attachments[key - 2].data)
          // }
          const pdfViewerFrame2 = document.getElementById('attachmentpdf')
          pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.purchaseRequest.attachments[key - 2].data}`)
          this.showPDF = true
        } else {
          this.imageSrc = this.purchaseRequest.attachments[key - 2].data
          this.showPDF = false
        }
        this.showAttachment = true
      }
    },
    resolveItemCounter(page, index) {
      let count = 0
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < (page - 1); i++) {
        count += this.chunksOfItemRequests[i].length
      }

      count += index + 1

      return count
    },
    base64ToUint8Array(base64) {
      const raw = atob(base64)
      const uint8Array = new Uint8Array(raw.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    },
    async enablePreview() {
      store.commit('appConfig/UPDATE_LOADER', true)
      const vm = this
      await this.calculateTotalPage()

      // start of pdf using html2PDF package
      const element = document.getElementById('pdfGenerateDiv')
      const opt = {
        margin: 1,
        filename: 'myfile.pdf',
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'], after: '.page-section' },
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          useCORS: true,
          scale: 2,
          dpi: 192,
          // windowWidth: 1024,
          letterRendering: true,
        },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'p' },
      }

      html2pdf().set(opt).from(element).toPdf()
        .get('pdf')
        .then(pdf => {
          vm.pdfData = pdf.output('datauristring')
          const base64Raw = vm.pdfData.split(',')[1]
          const pdfDataJS2 = vm.base64ToUint8Array(base64Raw)
          this.pdfDataJS = pdfDataJS2
          const pdfViewerFrame = document.getElementById('docpdf')
          // eslint-disable-next-line func-names
          pdfViewerFrame.onload = () => {
            pdfViewerFrame.contentWindow.PDFViewerApplication.open(pdfDataJS2)
          }
          pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          store.commit('appConfig/UPDATE_LOADER', false)
        })
      // end of pdf using html2PDF package
      // this.pdfData = data
      // this.prfPreview = true
    },
    async calculateTotalPage() {
      await this.$nextTick()
      this.chunksOfItemRequests = []
      this.chunkItemRequests = []
      // const logoHeight = document.getElementById('main-header').offsetHeight
      let requesterHeight = document.getElementById('requester-header').offsetHeight
      // const footerHeight = document.getElementById('footer-div').offsetHeight
      const logoHeight = 135
      if ((logoHeight + requesterHeight) > 800) {
        // document.getElementById('remarksField').style.fontSize = '7px'
        this.lowFont = true
        await this.$nextTick()
        requesterHeight = document.getElementById('requester-header').offsetHeight
      }
      // const requesterHeight = 200
      // footer height will be calculated based on assignee in other pdfs. for pr assignee is limited to 3
      const footerHeight = 210
      let contentHeight = 0
      this.pdfTotalPage = 0
      let totalItems = this.purchaseRequestForm.items.length
      let i = 0
      let sliceStart = 0
      let sliceEnd = 10
      if (totalItems > 10) {
        sliceEnd = 14
      }
      while (totalItems > 0) {
        if (i === 0) {
          contentHeight = 1000 - (logoHeight + requesterHeight + footerHeight)
        } else {
          contentHeight = 1000 - (logoHeight + footerHeight)
        }
        // console.log(contentHeight)
        this.chunkItemRequests = this.purchaseRequestForm.items.slice(sliceStart, sliceEnd)
        // eslint-disable-next-line no-await-in-loop
        await this.$nextTick()
        let tableHeight = document.getElementById('content-div').offsetHeight
        // console.log(tableHeight)
        if (tableHeight > contentHeight) {
          contentHeight += footerHeight
        }
        // // console.log(tableHeight)
        while (tableHeight > contentHeight) {
          sliceEnd -= 1
          this.chunkItemRequests = this.purchaseRequestForm.items.slice(sliceStart, sliceEnd)
          // eslint-disable-next-line no-await-in-loop
          await this.$nextTick()
          tableHeight = document.getElementById('content-div').offsetHeight
        }
        this.chunksOfItemRequests.push(this.chunkItemRequests)
        this.pdfTotalPage += 1
        i += 1
        sliceStart = sliceEnd
        sliceEnd += 17
        totalItems -= this.chunkItemRequests.length
      }

      let remainingHeight = 0
      const finalDataHeight = document.getElementById('content-div').offsetHeight
      this.gapHeight = 0
      const dHeight = 1080
      // if (this.isMobile()) {
      //   dHeight = 590
      // }
      if (this.pdfTotalPage === 1) {
        remainingHeight = dHeight - (logoHeight + requesterHeight + footerHeight + finalDataHeight)
        if (remainingHeight > 0) {
          this.gapHeight = remainingHeight
        }
        if (finalDataHeight >= 650) {
          this.pdfTotalPage += 1
          this.gapHeight = dHeight - (logoHeight + footerHeight)
        }
      } else {
        remainingHeight = dHeight - (logoHeight + footerHeight + finalDataHeight)
        if (remainingHeight > 0) {
          this.gapHeight = remainingHeight
        }
        if (finalDataHeight >= 850) {
          this.pdfTotalPage += 1
          this.gapHeight = dHeight - (logoHeight + footerHeight)
        }
      }
    },
    addComment() {
      this.$http
        .post('purchase/purchase-request/save-comment', { comment: this.extraComment, stage: 'in-request', purchaseRequest: this.$route.params.id })
        .then(response => {
          this.extraComment = ''
          this.prComments = response.data.comments
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.purchaseRequest.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    removeAttachment(url) {
      this.$http
        .post('purchase/purchase-request/remove-attachment', { url, purchaseRequest: this.$route.params.id })
        .then(response => {
          this.$emit('update:purchaseRequest', response.data.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveDescription() {
      const url = this.purchaseRequest.attachments[this.attachmentKey].data
      this.$http
        .post('purchase/purchase-request/save-description', { url, description: this.description, purchaseRequest: this.$route.params.id })
        .then(response => {
          this.$emit('update:purchaseRequest', response.data.data)
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    warningBeforeEdit() {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Editing the form now will <strong>reset the approval process</strong> and require all signatories to confirm and sign once again.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Edit this PRF',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-purchase-requests-edit', params: { id: this.$route.params.id } })
          }
        })
    },
    submitForApproval() {
      this.$swal({
        title: 'Sign and Send this PRF?',
        html: `You are proceeding to sign this PRF. The PRF will then be sent to ${this.purchaseRequestForm.confirmedBy ? this.purchaseRequestForm.confirmedBy.name : ''} for signing.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('url', window.location.origin)
            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/requester/submit`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.$swal({
                  title: 'Submitted for Approval',
                  html: 'This purchase request will be sent for verifying.<br>All relevant parties will receive a notification.',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(confirm => {
                    if (confirm.value) {
                      this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.id } })
                    }
                  })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    sendBackToRequester(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.sendBackToRequesterForm.validate().then(success => {
        if (success) {
          this.$refs['modal-send-back'].toggle('#toggle-btn')
          const formData = new FormData()
          formData.append('comment', this.comment)
          formData.append('stage', 'in-request')

          this.$http.post(`purchase/purchase-request/${this.$route.params.id}/send-back`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.$swal({
                title: 'PRF updated to Pending Edit',
                html: `The requester (${this.purchaseRequest.createdBy ? this.purchaseRequest.createdBy.name : ''}) has been informed of your comments. You may verify this PRF after the requester re-submits this PRF.`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/success.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(confirm => {
                  if (confirm.value) {
                    this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.id } })
                  }
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'comment') {
                    this.commentError = validationError.msg
                    this.commentValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },

    approvePRF() {
      const stage = this.$route.query.type
      let message = `You are proceeding to sign on this PRF. The PRF will then be sent to ${this.nextApproverName} for signing.`
      let confirmedMessage = `${this.purchaseRequest.caseID} will now be sent to the next assigned signatory (${this.nextApproverName}) for signing.`
      if (stage === 'verify') {
        message = 'You are proceeding to sign on this PRF.<br>The Purchase Request will enter Quotation stage.'
        confirmedMessage = `${this.purchaseRequest.caseID} is now ready for quotation(s) sourcing and uploading.`
      }
      this.$swal({
        title: `${stage === 'verify' ? 'Verify' : 'Approve'} This PRF?`,
        html: message,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('stage', stage)
            formData.append('url', window.location.origin)
            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/approve-prf`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.$swal({
                  title: `PRF ${stage === 'verify' ? 'Verified' : 'Signed'}`,
                  html: confirmedMessage,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(confirm => {
                    if (confirm.value) {
                      this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.id } })
                    }
                  })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    setData() {
      this.purchaseRequestID = this.purchaseRequest._id
      this.status = this.purchaseRequest.status
      this.stringID = this.purchaseRequest.stringID

      this.$http.get(`directory/users/${this.purchaseRequest.createdBy._id}/show`)
        .then(response => {
          this.user = response.data ?? {}
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    submitForm() {
      this.$refs.purchaseRequestStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`purchase/purchase-request/${this.purchaseRequestID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.purchaseRequest.status = response.data.data.status || 'confirmed'
              this.setData()
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/swiper.scss';
</style>
